import { useStaticQuery, graphql } from "gatsby"
import { colors } from "./presets"

function getTravelTypeColor(slug) {
  let TravelTypeColor = colors.NAVYBLUE
  // switch (slug) {
  //   case "tours":
  //     TravelTypeColor = colors.RED;
  //     break;
  //   case "destinations":
  //     TravelTypeColor = colors.ORANGE;
  //     break;
  //   case "circuits":
  //     TravelTypeColor = colors.GREEN;
  //     break;
  //   default:
  //     TravelTypeColor = colors.NAVYBLUE;
  // }
  return TravelTypeColor
}

export default getTravelTypeColor
