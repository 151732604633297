import React from "react"
import styled from "@emotion/styled"
import MapaBg from "../images/mapaBg.jpg"

const BgMap = styled.div(
  {
    position: `relative`,
    "&:before": {
      content: "''",
      background: `url("${MapaBg}")`,
      width: `100%`,
      height: `auto`,
      position: `absolute`,
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      opacity: 0.6,
    },
  },
  props => ({
    "&:before": {
      backgroundSize: props.Size ? props.Size : "cover",
    },
  })
)

export default BgMap
