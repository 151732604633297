import React from "react"
import { Link, graphql } from "gatsby"
import { FormattedMessage } from "react-intl"
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import lang from "../../data/languages"
import Img from "gatsby-image"
import getTravelTypeColor from "../../utils/getTypeColor"
import badgeDeals from "../../images/badgeDeals.svg"

import { colors } from "../../utils/presets"

const TravelItem = ({ node }) => {
  const {
    node_locale,
    slug,
    title,
    travelType,
    photo,
    isHotDeals,
    price,
    dealsPrice,
  } = node
  const nodeLocale =
    node_locale === lang.defaultLangKey ? `` : `/${node_locale}`
  return (
    <div className="column is-4">
      <Link
        className={`travel-item-link`}
        css={{
          color: `inherit`,
          textDecoration: `none`,
          display: `block`,
          position: `relative`,
          paddingBottom: 30,
        }}
        to={`${nodeLocale}/${travelType.slug}/${slug}`}
      >
        {isHotDeals && (
          <div
            css={css`
              position: absolute;
              top: -13px;
              left: -12px;
              z-index: 22;
            `}
          >
            <div
              css={css`
                position: absolute;
                transform: rotate(-44deg);
                color: #fff;
                top: 31px;
                left: 5px;
              `}
            >
              {" "}
              <FormattedMessage id="HotDeals" />
            </div>

            <img src={badgeDeals} />
          </div>
        )}

        <div className="card">
          <div
            className="card-image has-overlay"
            css={{
              transition: `opacity 0.5s ease-in-out`,
              "&:before": {
                transition: `opacity 0.5s ease-in-out`,
                background: `linear-gradient(to bottom, rgba(0, 8, 64, 0) 0%, rgba(0, 8, 64, 0) 0%, ${getTravelTypeColor(
                  travelType.slug
                )} 100%)`,
                opacity: 0.2,
              },

              "&:hover:before": {
                opacity: 1,
              },
            }}
          >
            <Img
              alt={title}
              css={{
                margin: 0,
              }}
              fluid={photo.fluid}
            />
            <div
              className={`caption-view`}
              css={{
                position: `absolute`,
                color: `whitesmoke`,
                top: `50%`,
                left: 0,
                right: 0,
                bottom: 0,
                textAlign: `center`,
                zIndex: 22,
                opacity: 0,
              }}
            >
              <span
                css={{
                  border: `1px solid`,
                  padding: `8px 22px`,
                  borderRadius: 4,
                }}
              >
                <FormattedMessage id={`ViewMore`} />
              </span>
            </div>
            {travelType.slug !== "destinations" &&
              (price && (
                <div
                  css={css`
                    position: absolute;
                    bottom: 16px;
                    right: 13px;
                    z-index: 22;
                    color: #fff;
                  `}
                >
                  <div
                    css={{
                      background: colors.ORANGE,
                      padding: 8,
                      borderRadius: 4,
                    }}
                  >
                    <span
                      css={css`
                        ${isHotDeals &&
                          `text-decoration: line-through;font-size:13px;`}
                        margin-right: 5px;
                      `}
                    >
                      <FormattedMessage id={`From`} /> $ {price}
                    </span>
                    {dealsPrice && (
                      <span>
                        {" "}
                        <FormattedMessage id={`Now`} /> $ {dealsPrice}
                      </span>
                    )}
                  </div>
                </div>
              ))}
          </div>
          <div className="card-content">
            <div className="card-title">{title}</div>
            <span
              css={{
                color: colors.NAVYBLUE,
                fontWeight: `bold`,
              }}
            >
              {travelType.typeTitle}
            </span>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default TravelItem
