import React from "react"
import Layout from "../../layout"
import PagesHero from "../../pages"
import Section from "../../section"
import PrimaryTitle from "../../PrimaryTitle"
import { FormattedMessage, FormattedHTMLMessage } from "react-intl"
import { graphql, useStaticQuery, Link } from "gatsby"
/** @jsx jsx */
import { jsx } from "@emotion/core"

import BgMap from "../../BgMap"
import TravelItem from "../../travel-item/travel-item"
import lang from "../../../data/languages"

import noData from "../../../images/no-data.svg"

const Deals = props => {
  const currentLang =
    props.langKey === lang.defaultLangKey ? `` : `/${props.langKey}`

  const data = useStaticQuery(graphql`
    query {
      us: allContentfulTravels(
        filter: { isHotDeals: { eq: true }, node_locale: { eq: "en-US" } }
      ) {
        totalCount
        edges {
          node {
            id
            title
            shortDescription
            slug
            node_locale
            isHotDeals
            price
            dealsPrice
            photo {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
            travelType {
              slug
              typeTitle
            }
          }
        }
      }
      es: allContentfulTravels(
        filter: { isHotDeals: { eq: true }, node_locale: { eq: "es" } }
      ) {
        totalCount
        edges {
          node {
            id
            title
            shortDescription
            slug
            isHotDeals
            price
            dealsPrice
            node_locale
            photo {
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
            travelType {
              slug
              typeTitle
            }
          }
        }
      }
    }
  `)

  const usTravelsEdges = props.langKey === `es` ? data.es.edges : data.us.edges
  const _totalCount =
    props.langKey === `es` ? data.es.totalCount : data.us.totalCount
  return (
    <Layout location={props.location}>
      <PagesHero>
        <BgMap Size={"contain"} css={{ marginBottom: 60 }}>
          <Section>
            <div className="has-text-centered mb-6">
              <PrimaryTitle textId="Deals" />

              <FormattedMessage id="DealsPhrase" />
            </div>
            {_totalCount > 0 ? (
              <div className="columns is-multiline">
                {usTravelsEdges.map(({ node }, i) => (
                  <TravelItem node={node} key={node.id} />
                ))}
              </div>
            ) : (
              <section css={{ textAlign: `center` }}>
                <img width="350" src={noData} alt="No active promotion" />
                <h4>
                  {" "}
                  Lo sentimos 😕, no tenemos ninguna promocion en este momento.
                </h4>
              </section>
            )}
          </Section>
        </BgMap>
      </PagesHero>
    </Layout>
  )
}

export default Deals
